type App = { bootstrap: (element: Document | HTMLElement) => void };

async function loadAdminApp() {
    const auth = await import('./auth');
    await auth.redirectToLoginPageIfUnauthorized();
    return await import('./admin-app');
}

async function loadActivateApp() {
    return await import('./activate-app');
}

async function loadApp(window: Window): Promise<App> {
    const currentPage = window.location.pathname;
    const isAdminPage = currentPage.indexOf('/user/activate') === -1;
    return await (isAdminPage ? loadAdminApp() : loadActivateApp());
}

function redirectOnLegacyHashUrl(window: Window) {
    const hasPath = window.location.pathname !== '/';
    const hasHash = window.location.hash !== '';
    const isOldUrl = !hasPath && hasHash;
    if (!isOldUrl) return;
    console.log('Redirecting to non-hash based url...');
    const newPath = window.location.hash.replace(/^#\/?/, '');
    const newUrl = new URL(newPath, window.location.origin);
    window.location.replace(newUrl.toString());
}

function addSlowLoadingSpinner(element: Element) {
    // show the spinner if we've been waiting a long time...
    element.classList.add('loadable');
    const slowLoadingTimer = setTimeout(() => {
        console.warn('Loading is taking a long time...');
        element.classList.add('loading');
    }, 3500);
    return () => {
        clearTimeout(slowLoadingTimer);
        element.classList.remove('loadable');
        element.classList.remove('loading');
    };
}

export async function bootstrap(window: Window) {
    console.log('Bootstrapping application...');
    const document = window.document;
    redirectOnLegacyHashUrl(window);
    const stopSpinner = addSlowLoadingSpinner(document.body);
    try {
        const app = await loadApp(window);
        stopSpinner();
        return app.bootstrap(window.document);
    } catch (error) {
        console.error(error);
        throw error;
    }
}
